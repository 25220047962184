import React from 'react';
import { Text, View, Dimensions, Image, Animated, PanResponder, TouchableOpacity } from 'react-native';
import { Button, ButtonGroup } from 'reactstrap';
import { Player, ControlBar, Shortcut, BigPlayButton } from 'video-react';
import EmailForm from './EmailForm.js'

const dev = false;

const { detect } = require('detect-browser');
const uuidv4 = require('uuid/v4');
const webHost = window.location.host;
const videoApiURL = 'https://'+webHost+'/api.php';
const voteApiURL = 'https://'+webHost+'/vote.php';
const logoURL = 'https://refereechallenge.s3-us-west-2.amazonaws.com/images/logos/';

const clipsToPreload = 4;
const indexDistanceToLoad = 2;
const emailCollectionAfter = 6;
const appVersion = '1.5';
const showPagination = true;

const hitSlopUp = {top: 100, bottom: 10, left: 100, right: 10};
const hitSlopDown = {top: 100, bottom: 10, left: 10, right: 100};
const browser = detect();
const userSessionID = uuidv4();

let SCREEN_HEIGHT = Math.min(Dimensions.get('window').height,896)-20;
let SCREEN_WIDTH = Math.min(Dimensions.get('window').width,420)-20;

const backgroundColor = '#1e1e1e';
const bubbleBackgroundColor = '#000000';

class App extends React.Component {

	constructor() {
		super();

		this.players = [];
		this.position = new Animated.ValueXY();
		this.state = {
			params: {},
			currentIndex: 0,
			page: 1,
			clips: [],
			didPassEmail: false,
			clipCount: 0,
			hasSubmitted: false,
			showCardDisplay: 'none'
		};

		this.initializeClips();

		this.rotate = this.position.x.interpolate({
			inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
			outputRange: ['-10deg', '0deg', '10deg'],
			extrapolate: 'clamp'
		});

		this.rotateAndTranslate = {
			transform: [{
				rotate: this.rotate
			},
				...this.position.getTranslateTransform()
			]
		};

		this.downVoteOpacity = this.position.x.interpolate({
			inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
			outputRange: [0, 0, 1],
			extrapolate: 'clamp'
		});
		this.upVoteOpacity = this.position.x.interpolate({
			inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
			outputRange: [1, 0, 0],
			extrapolate: 'clamp'
		});

		this.nextCardOpacity = this.position.x.interpolate({
			inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
			outputRange: [1, 0, 1],
			extrapolate: 'clamp'
		});
		this.nextCardScale = this.position.x.interpolate({
			inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
			outputRange: [1, 0.8, 1],
			extrapolate: 'clamp'
		});

		this.showAlert();

		this.PanResponder = PanResponder.create({

			onStartShouldSetPanResponder: (evt, gestureState) => true,
			onPanResponderMove: (evt, gestureState) => {
				this.position.setValue({ x: gestureState.dx, y: gestureState.dy })
			},
			onPanResponderRelease: (evt, gestureState) => {
				if (gestureState.dx > 120) {
					// Down Vote = Swipe Right
					this.downVote(gestureState.dy);
				}
				else if (gestureState.dx < -120) {
					// Up Vote = Swipe Left
					this.upVote(gestureState.dy);
				}
				else {
					Animated.spring(this.position, {
						toValue: { x: 0, y: 0 },
						friction: 4
					}).start()
				}
			}
		});

		this.emailPanResponder = PanResponder.create({

			onStartShouldSetPanResponder: (evt, gestureState) => true,
			onPanResponderMove: (evt, gestureState) => {
				this.position.setValue({ x: gestureState.dx, y: gestureState.dy })
			},
			onPanResponderRelease: (evt, gestureState) => {
				if (gestureState.dx > 120) {
					// Down Vote = Swipe Right
					Animated.spring(this.position, {
						toValue: { x: SCREEN_WIDTH + 100, y: gestureState.dy }
					}).start(() => {
						this.setState({ didPassEmail: true }, () => {
							this.position.setValue({ x: 0, y: 0 });
						});
						// Submit Email if Exists
						this.submitEmail();
					});
				}
				else if (gestureState.dx < -120) {
					// Up Vote = Swipe Left
					Animated.spring(this.position, {
						toValue: { x: -SCREEN_WIDTH - 100, y: gestureState.dy }
					}).start(() => {
						this.setState({ didPassEmail: true }, () => {
							this.position.setValue({ x: 0, y: 0 });
						});
						// Submit Email if Exists
						this.submitEmail();
					});
				}
				else {
					Animated.spring(this.position, {
						toValue: { x: 0, y: 0 },
						friction: 4
					}).start()
				}
			}
		});
	}

	handleToUpdate = (submittedEmail) => {
		// console.log('handleToUpdate(): ',submittedEmail);
		this.setState({
			hasSubmitted: submittedEmail
		});
	};

	submitEmail = () => {
		if (this.state.params.hasOwnProperty('email')) {
			// console.log(this.state.params);
			const emailApiURL = 'https://'+window.location.host+'/email.php';
			fetch(emailApiURL, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(this.state.params),
			})
				.then((response) => response.json())
				.then((voteResponse) => {
					this.handleToUpdate(true);
				})
				.catch((error) => {
					console.log('error');
					// console.error(error);
				});
		}
	};

	validateEmail(email) {
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return (emailRex.test(email) && email !== '');
	}

	handleSkip = () => {
		// console.log("Skip Submitting Email");
		// Down Vote = Swipe Right
		Animated.spring(this.position, {
			toValue: { x: SCREEN_WIDTH + 100, y: 150 }
		}).start(() => {
			this.setState({ didPassEmail: true }, () => {
				this.position.setValue({ x: 0, y: 0 });
			});
		});
		this.submitEmail();
	};

	handleUserInputs = (params) => {
		if (params.email !== undefined && params.email !== null && this.validateEmail(params.email)) {
			this.setState({
				params: params
			});
		}
	};

	componentDidMount() {

		this.PanResponder = PanResponder.create({

			onStartShouldSetPanResponder: (evt, gestureState) => true,
			onPanResponderMove: (evt, gestureState) => {

				this.position.setValue({ x: gestureState.dx, y: gestureState.dy })
			},
			onPanResponderRelease: (evt, gestureState) => {

				if (gestureState.dx > 120) {
					// Down Vote = Swipe Right
					this.downVote(gestureState.dy);
				}
				else if (gestureState.dx < -120) {
					// Up Vote = Swipe Left
					this.upVote(gestureState.dy);
				}
				else {
					Animated.spring(this.position, {
						toValue: { x: 0, y: 0 },
						friction: 4
					}).start()
				}
			}
		});

		this.emailPanResponder = PanResponder.create({

			onStartShouldSetPanResponder: (evt, gestureState) => true,
			onPanResponderMove: (evt, gestureState) => {
				this.position.setValue({ x: gestureState.dx, y: gestureState.dy })
			},
			onPanResponderRelease: (evt, gestureState) => {
				if (gestureState.dx > 120) {
					// Down Vote = Swipe Right
					Animated.spring(this.position, {
						toValue: { x: SCREEN_WIDTH + 100, y: gestureState.dy }
					}).start(() => {
						this.setState({ didPassEmail: true }, () => {
							this.position.setValue({ x: 0, y: 0 });
						});
						// Submit Email if Exists
						this.submitEmail();
					});
				}
				else if (gestureState.dx < -120) {
					// Up Vote = Swipe Left
					Animated.spring(this.position, {
						toValue: { x: -SCREEN_WIDTH - 100, y: gestureState.dy }
					}).start(() => {
						this.setState({ didPassEmail: true }, () => {
							this.position.setValue({ x: 0, y: 0 });
						});
						// Submit Email if Exists
						this.submitEmail();
					});
				}
				else {
					Animated.spring(this.position, {
						toValue: { x: 0, y: 0 },
						friction: 4
					}).start()
				}
			}
		});
	}

	showAlert = () => {
		alert('Swipe\n\nLeft 👈 for good calls 👍 \n\n' +
			'Right 👉 for bad calls 👎');
	};

	getTestClips = () => {
		if (this.state.clips.length < 8) {
			let clips = [
				{
					id: "0",
					uri: 'https://refereechallenge.s3-us-west-2.amazonaws.com/videos/NBA_58dc0509-9ea4-4e43-81f4-d2d4c3800921_6c71d192-9cf6-477c-a655-e1a0e752a70a.mp4',
					gameId: '58dc0509-9ea4-4e43-81f4-d2d4c3800921',
					eventId: '6c71d192-9cf6-477c-a655-e1a0e752a70a',
					league: 'NBA',
					homeTeam: 'MIA',
					awayTeam: 'WAS',
					homeTeamName: 'Heat',
					awayTeamName: 'Wizards',
					homeTeamMarket: 'Miami',
					awayTeamMarket: 'Washington',
					eventDesc: 'MIA - Bam Adebayo shooting foul (Jordan McRae draws the foul)',
					gameClock: '00:23',
					period: 'Q4',
					homeTeamScore: 69,
					awayTeamScore: 48
				},
				{
					id: "1",
					uri: 'https://refereechallenge.s3-us-west-2.amazonaws.com/videos/NBA_58dc0509-9ea4-4e43-81f4-d2d4c3800921_6c71d192-9cf6-477c-a655-e1a0e752a70a.mp4',
					gameId: '58dc0509-9ea4-4e43-81f4-d2d4c3800921',
					eventId: '6c71d192-9cf6-477c-a655-e1a0e752a70a',
					league: 'NBA',
					homeTeam: 'LAL',
					awayTeam: 'POR',
					homeTeamName: 'Lakers',
					awayTeamName: 'Trail Blazers',
					homeTeamMarket: 'Los Angeles',
					awayTeamMarket: 'Portland',
					eventDesc: 'MIA - Bam Adebayo shooting foul (Jordan McRae draws the foul)',
					gameClock: '00:23',
					period: 'Q4',
					homeTeamScore: 69,
					awayTeamScore: 48
				},
				{
					id: "2",
					uri: 'https://refereechallenge.s3-us-west-2.amazonaws.com/videos/NBA_58dc0509-9ea4-4e43-81f4-d2d4c3800921_6c71d192-9cf6-477c-a655-e1a0e752a70a.mp4',
					gameId: '58dc0509-9ea4-4e43-81f4-d2d4c3800921',
					eventId: '6c71d192-9cf6-477c-a655-e1a0e752a70a',
					league: 'NBA',
					homeTeam: 'BOS',
					awayTeam: 'SAS',
					homeTeamName: 'Celtics',
					awayTeamName: 'Spurs',
					homeTeamMarket: 'Boston',
					awayTeamMarket: 'San Antonio',
					eventDesc: 'MIA - Bam Adebayo shooting foul (Jordan McRae draws the foul)',
					gameClock: '00:23',
					period: 'Q4',
					homeTeamScore: 69,
					awayTeamScore: 48
				},
				{
					id: "3",
					uri: 'https://refereechallenge.s3-us-west-2.amazonaws.com/videos/NBA_58dc0509-9ea4-4e43-81f4-d2d4c3800921_6c71d192-9cf6-477c-a655-e1a0e752a70a.mp4',
					gameId: '58dc0509-9ea4-4e43-81f4-d2d4c3800921',
					eventId: '6c71d192-9cf6-477c-a655-e1a0e752a70a',
					league: 'NBA',
					homeTeam: 'MIL',
					awayTeam: 'TOR',
					homeTeamName: 'Bucks',
					awayTeamName: 'Raptors',
					homeTeamMarket: 'Milwaukee',
					awayTeamMarket: 'Toronto',
					eventDesc: 'MIA - Bam Adebayo shooting foul (Jordan McRae draws the foul)',
					gameClock: '00:23',
					period: 'Q4',
					homeTeamScore: 69,
					awayTeamScore: 48
				}
			];
			this.setState({
				clipCount: this.state.clipCount+clips.length
			});

			for (const n in clips) {
				if (!clips.hasOwnProperty(n)) { continue; }
				this.state.clips.push(clips[n]);
			}
			this.setState({
				clips: this.state.clips,
				page: this.state.page+1
			});
		}
	};

	// API Related Methods
	getClips = () => {
		let url = videoApiURL + '?page=' + this.state.page + '&limit=' + clipsToPreload;
		if (this.state.page === 1) {
			url += '&sessionId=' + encodeURIComponent(userSessionID);
			url += '&browser=' +encodeURIComponent(browser.name);
			url += '&version=' + encodeURIComponent(browser.version);
			url += '&os=' + encodeURIComponent(browser.os);
		}
		url += '&rcv=' + appVersion;
		url += '&otherData=' + encodeURIComponent(JSON.stringify({
			appScreenHeight: SCREEN_HEIGHT,
			appScreenWidth: SCREEN_WIDTH,
			actualScreenHeight: Dimensions.get('window').height,
			actualScreenWidth: Dimensions.get('window').width,
			showPagination: showPagination,
			emailCollectionAfter: emailCollectionAfter
		}));
		fetch(url, {
			method: 'GET'
		})
		.then((response) => response.json())
		.then((clipInfo) => {
			// console.log(clipInfo);
			if (clipInfo.hasOwnProperty('clips')) {
				let newClips = clipInfo['clips'];
				for (const n in newClips) {
					if (!newClips.hasOwnProperty(n)) { continue; }
					this.state.clips.push(newClips[n]);
				}
				this.setState({
					clips: this.state.clips,
					page: this.state.page+1
				});
			}
			if (clipInfo.hasOwnProperty('total')) {
				this.setState({
					clipCount: parseInt(clipInfo['total'])
				});
			}
		})
		.catch((error) => {
			console.error('error');
		});
	};

	castVote = (eventId,vote) => {
		if (dev) { return; }
		let params = {
			uuid: userSessionID,
			eventId: eventId,
			vote: vote
		};
		fetch(voteApiURL, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(params),
		})
		.then((response) => response.json())
		.then((voteResponse) => {
			// console.log('voted');
		})
		.catch((error) => {
			// console.error('error');
		});
	};

	// Setters
	initializeClips = () => {
		// clips would be in a callback from getClips()
		if (dev) {
			this.getTestClips();
		}
		else {
			this.getClips();
		}
	};

	addClips = () => {

		if (this.state.currentIndex >= this.state.clips.length-indexDistanceToLoad) {
			if (dev) {
				this.getTestClips();
			}
			else {
				this.getClips();
			}
		}
	};

	downVote = (y) => {
		let event = this.state.clips[this.state.currentIndex];
		Animated.spring(this.position, {
			toValue: { x: SCREEN_WIDTH + 100, y: y }
		}).start(() => {
			this.setState({ currentIndex: this.state.currentIndex + 1 }, () => {
				this.position.setValue({ x: 0, y: 0 });
			});

			this.addClips();
		});
		this.castVote(event.eventId,2);
	};

	upVote = (y) => {
		let event = this.state.clips[this.state.currentIndex];
		Animated.spring(this.position, {
			toValue: { x: -SCREEN_WIDTH - 100, y: y }
		}).start(() => {
			this.setState({ currentIndex: this.state.currentIndex + 1 }, () => {
				this.position.setValue({ x: 0, y: 0 });
			});

			this.addClips();
		});
		this.castVote(event.eventId,1);
	};

	// UI Related Methods
	renderVideo = (item, i) => {
		let autoPlay = (i === 0 || i%emailCollectionAfter === 0);
		return (
			<View style={{
				marginTop: '-0.5em',
				zIndex: 0,
				position: 'relative',
				borderRadius:'10px',
				overflow:'hidden' }}>
				{/* YouTube */}
				{/*<iframe*/}
				{/*	src="https://www.youtube.com/embed/Ue6qNQ88ms4?autoplay=1&controls=0&loop=1&modestbranding=1&mute=1&version=3&playlist=Ue6qNQ88ms4"*/}
				{/*	style={{ flex: 1 }}*/}
				{/*	frameBorder="0"*/}
				{/*	allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
				{/*	allowFullScreen />*/}

				{/* Vimeo */}
				{/*<iframe*/}
				{/*	src="https://player.vimeo.com/video/396248441?autoplay=1&muted=1&loop=1"*/}
				{/*	frameBorder="0"*/}
				{/*	style={{ flex: 1, width: '100%' }}*/}
				{/*	allow="autoplay; fullscreen" allowFullScreen />*/}

				{/* S3 */}
				<Player
					ref={(player) => { this.players.splice(this.state.currentIndex, 0, player) }}
					fluid={true}
					preload="auto"
					playsInline={true}
					autoPlay={autoPlay}
					loop={true}
					style={{ flex: 1, width: '100%', position: 'relative !important' }}
				>
					<source src={item.uri} />
					<ControlBar disableCompletely={true} disableDefaultControls={true} style={{paddingTop: 0}} />
					<Shortcut clickable={true} dblclickable={false} />
					<BigPlayButton position="center" />
				</Player>
			</View>
		);
	};

	homeTeam = (item) => {
		let imgURL = logoURL+item.league+'/'+item.homeTeam+'.png';
		return (
			<View style={{ top: 5, right: 30, position: 'absolute', alignItems: 'center' }}>
				<Image
					style={{ width: 60, height: 60 }}
					source={{uri: imgURL}} />
				<Text style={{ fontSize: 10, color: '#CDCDCD', paddingTop: 8 }}>{item.homeTeamMarket}</Text>
				<Text style={{ fontSize: 18, color: '#fff', paddingTop: 0 }}>{item.homeTeamName}</Text>
				<Text style={{ fontSize: 30, color: '#fff', fontFamily: 'impact', paddingTop: 3 }}>{item.homeTeamScore}</Text>
			</View>
		);
	};

	awayTeam = (item) => {
		let imgURL = logoURL+item.league+'/'+item.awayTeam+'.png';
		return (
			<View style={{ top: 5, left: 30, position: 'absolute', alignItems: 'center' }}>
				<Image
					style={{ width: 60, height: 60 }}
					source={{uri: imgURL}} />
				<Text style={{ fontSize: 10, color: '#CDCDCD', paddingTop: 8 }}>{item.awayTeamMarket}</Text>
				<Text style={{ fontSize: 18, color: '#fff', paddingTop: 0 }}>{item.awayTeamName}</Text>
				<Text style={{ fontSize: 30, color: '#fff', fontFamily: 'impact', paddingTop: 3 }}>{item.awayTeamScore}</Text>
			</View>
		);
	};

	vsView = () => {
		return (
			<View style={{alignItems: 'center' }}>
				<Text style={{ fontSize: 30, color: '#fff', fontFamily: 'impact' }}>vs</Text>
			</View>
		);
	};

	gameSummary = (item) => {
		return (
			<View style={{ top: '25%', alignItems: 'center' }}>
				<Text style={{ fontSize: 12, color: '#000', background: '#ffffff', padding: '5px', borderRadius: '5px',  }}>{item.gameClock}</Text>
				<Text style={{ fontSize: 12, color: '#fff', background: backgroundColor, padding: '5px', borderRadius: '5px', marginTop: '1em'  }}>{item.period}</Text>
			</View>
		);
	};

	eventDescription = (item) => {
		return (
			<React.Fragment>
				<View style={{ position: 'relative', marginTop: 5, alignItems: 'center' }}>
					{/*<Image*/}
					{/*	source={require('./assets/images/share.png')}*/}
					{/*	style={{position: 'absolute', width: 18, height: 24, left: SCREEN_WIDTH-35}}*/}
					{/*/>*/}
				</View>
				<View style={{ position: 'relative', width: '90%', alignItems: 'center' }}>
					<Text style={{ fontSize: '1em', color: '#fff', fontFamily: 'impact' }}>{item.eventDesc}</Text>
				</View>
			</React.Fragment>
		);
	};

	upVoteAnimatedView = (useButton=true) => {
		if (useButton) {
			return (
				<Button style={{
					display: 'inline',
					margin: '0 1em',
					borderRadius: '10px',
					border: '2px solid #303030',
					background: bubbleBackgroundColor}}
						onClick={()=> this.upVote(-150) }>
					<Image
						style={{ width: 40, height: 40, margin: 8 }}
						source={require('./assets/images/like.png')} />
				</Button>
			);
		}
		return (
			<React.Fragment>
				<Animated.View style={{opacity: this.upVoteOpacity, top: SCREEN_HEIGHT-100, position: 'absolute', zIndex: 10}}>
					<TouchableOpacity onPress={()=> this.upVote(-150) } hitSlop={hitSlopUp}>
						<Image
							style={{ width: 62, height: 78, left: 80, zIndex: 10 }}
							source={require('./assets/images/upVote-voted.png')} />
					</TouchableOpacity>
				</Animated.View>
				<Animated.View style={{ top: SCREEN_HEIGHT-100, position: 'absolute'}}>
					<TouchableOpacity onPress={()=> this.upVote(-150) } hitSlop={hitSlopUp}>
						<Image
							style={{ width: 62, height: 78, left: 80 }}
							source={require('./assets/images/upVote.png')} />
					</TouchableOpacity>
				</Animated.View>
			</React.Fragment>
		);
	};

	downVoteAnimatedView = (useButton=true) => {
		if (useButton) {
			return (
				<Button style={{
					display: 'inline',
					margin: '0 1em',
					borderRadius: '10px',
					border: '2px solid #303030',
					background: bubbleBackgroundColor}}
						onClick={()=> this.downVote(150) }>
					<Image
						style={{ width: 40, height: 40, margin: 8 }}
						source={require('./assets/images/dislike.png')} />
				</Button>
			);
		}
		return (
			<React.Fragment>
				<Animated.View style={{opacity: this.downVoteOpacity, top: SCREEN_HEIGHT-100, position: 'absolute', zIndex: 10}}>
					<TouchableOpacity onPress={()=> this.downVote(150) } hitSlop={hitSlopDown}>
						<Image
							style={{ width: 62, height: 78, left: SCREEN_WIDTH-175, zIndex: 10 }}
							source={require('./assets/images/downVote-voted.png')} />
					</TouchableOpacity>
				</Animated.View>
				<Animated.View style={{ top: SCREEN_HEIGHT-100, position: 'absolute'}}>
					<TouchableOpacity onPress={()=> this.downVote(150) } hitSlop={hitSlopDown}>
						<Image
							style={{ width: 62, height: 78, left: SCREEN_WIDTH-175 }}
							source={require('./assets/images/downVote.png')} />
					</TouchableOpacity>
				</Animated.View>
			</React.Fragment>
		);
	};

	gameSummaryView = (item) => {
		return (
			<View
				style={{
					width: '90%',
					height: '10em',
					padding: '10px',
					marginTop: '1em',
					border: '2px solid #fff',
					borderRadius: '15px',
					backgroundColor: '#000',
					zIndex: 100
				}}
			>
				{/*{this.vsView()}*/}
				{this.homeTeam(item)}
				{this.awayTeam(item)}
				{this.gameSummary(item)}
			</View>
		)
	};

	voteButtons = () => {
		return (
			<ButtonGroup style={{position: 'absolute', bottom: '2em'}}>
				{this.upVoteAnimatedView()}
				{this.downVoteAnimatedView()}
			</ButtonGroup>
		);
	};

	pagination = (currentPage=true) => {
		if (showPagination) {
			let activePage = currentPage ? this.state.currentIndex+1 : this.state.currentIndex+2;
			return (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
						fontSize: 12,
						color: '#fff',
						position: 'absolute',
						bottom: '-10px'
					}}>{activePage} of {this.state.clipCount}</p>
			);
		}
	};

	renderClips = () => {
		if (this.state.clips.length === this.state.currentIndex) {
			// Finished, ask for email
			return (<EmailForm
				sessionId={userSessionID}
				continueVoting={false}
				collectedAt={this.state.clips.length}
				didPassEmail={this.state.hasSubmitted}
				handleToUpdate = {this.handleToUpdate.bind(this)}
				totalClips = {this.state.clipCount}
				handleUserInputs = {this.handleUserInputs.bind(this)}
			/>);
		}
		else {
			return this.state.clips.map((item, i) => {
				if (i < this.state.currentIndex) {
					// Old cards, already voted
					return null
				}
				else if (i !== 0 && i%emailCollectionAfter === 0 && !this.state.didPassEmail && i === this.state.currentIndex) {
					// Midway Email Collection
					let emailForm = <EmailForm
						sessionId={userSessionID}
						continueVoting={true}
						collectedAt={i}
						didPassEmail={this.state.hasSubmitted}
						handleToUpdate = {this.handleToUpdate.bind(this)}
						totalClips = {this.state.clipCount}
						skipSubmit = {this.handleSkip.bind(this)}
						handleUserInputs = {this.handleUserInputs.bind(this)}
					/>;
					return (
						<Animated.View
							{...this.emailPanResponder.panHandlers}
							key="emailFormP" style={[this.rotateAndTranslate, { height: SCREEN_HEIGHT - 10, width: SCREEN_WIDTH, padding: 10, position: 'absolute', zIndex: 10000 }]}>
							{emailForm}
						</Animated.View>
					)
				}
				else if (i !== 0 && i%emailCollectionAfter === 0 && !this.state.didPassEmail && i === this.state.currentIndex+1) {
					// Email collection form stacked behind video card
					return (
						<Animated.View
							key="emailFormS" style={[{
							opacity: this.nextCardOpacity,
							backgroundColor: backgroundColor,
							transform: [{ scale: this.nextCardScale }],
							height: SCREEN_HEIGHT - 10,
							width: SCREEN_WIDTH,
							padding: 10,
							position: 'absolute',
							alignItems: 'center',
						}]}>
							<EmailForm
								sessionId={userSessionID}
								continueVoting={true}
								collectedAt={i}
								didPassEmail={this.state.hasSubmitted}
								handleToUpdate = {this.handleToUpdate.bind(this)}
								totalClips = {this.state.clipCount}
								handleUserInputs = {this.handleUserInputs.bind(this)}
							/>
						</Animated.View>
					)
				}
				else if (i === this.state.currentIndex) {
					// Current Video Card to vote on
					if (this.players[this.state.currentIndex] !== undefined) {
						this.players[this.state.currentIndex].play();
					}
					return (
						<Animated.View
							{...this.PanResponder.panHandlers}
							key={item.id} style={[this.rotateAndTranslate,
							{
								height: SCREEN_HEIGHT - 10,
								width: SCREEN_WIDTH,
								padding: 10,
								position: 'absolute',
								backgroundColor: backgroundColor,
								alignItems: 'center',
								// border: '2px solid #303030',
								// borderRadius: '5px',
							}]}>

							{/* Header Div with Logos, scores, team names, quarter, and clock */}
							{this.gameSummaryView(item)}

							{/* Video */}
							{this.renderVideo(item,i)}

							{/* Event Description */}
							{this.eventDescription(item)}

							{/* Vote Buttons */}
							{this.voteButtons()}

							{/* Pagination */}
							{this.pagination(true)}
						</Animated.View>
					)
				}
				else {
					// Next Video Card to vote on
					console.log("Next Video Card to vote on");
					console.log("i: ", i);
					console.log("opactiy: ", this.nextCardOpacity);

					let opacity = i%7 === 0 ? this.position.x.interpolate({
						inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
						outputRange: [1, 0.25, 1],
						extrapolate: 'clamp'
					}) : this.nextCardOpacity;
					return (
						<Animated.View
							key={item.id} style={[{
							opacity: opacity,
							backgroundColor: backgroundColor,
							transform: [{ scale: this.nextCardScale }],
							height: SCREEN_HEIGHT - 10,
							width: SCREEN_WIDTH,
							padding: 10,
							position: 'absolute',
							alignItems: 'center',
							// border: '2px solid #303030',
							// borderRadius: '5px',
						}]}>
							{/* Header Div with Logos, scores, team names, quarter, and clock */}
							{this.gameSummaryView(item)}

							{/* Video */}
							{this.renderVideo(item,i)}

							{/* Event Description */}
							{this.eventDescription(item)}

							{/* Vote Buttons */}
							{this.voteButtons()}

							{/* Pagination */}
							{this.pagination(false)}

						</Animated.View>
					)
				}
			}).reverse()
		}
	};

	render() {
		return (
			<View style={{ flex: 1, backgroundColor: backgroundColor, alignItems: 'center' }}>
				<View style={{ height: 15, alignItems: 'center', zIndex:10000 }}>
					<Text style={{margin: 5, fontSize: 17, color: '#fff', fontFamily: 'impact' }}>Referee Challenge</Text>
				</View>
				<View style={{  alignItems: 'center' }}>
					{this.renderClips()}
				</View>
				<View style={{ height: 5 }}>
				</View>
			</View>
		);
	}
}

export default App;