import React, { Component, useState } from 'react';
import {
  Container, Col, Row, Form,
  FormGroup, Label, Input,
  Button, FormText, FormFeedback,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import './App.css';

let textStyle = {
  color: '#ffffff',
  textAlign: 'center',
  fontFamily: 'impact'
};

const TeamModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  
  let handleTeamChangeLI = props.handleTeamChangeLI;

  console.log("window.location.hostname",window.location.hostname);
  switch (window.location.hostname) {
    case 'nfl.refereechallenge.com':
      return (
          <div>
            <Button onClick={toggle} style={{width: '100%', background: '#1e1e1e'}}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
              <ModalHeader style={{background: '#000'}}>
                Select Favorite Teams
              </ModalHeader>
              <ModalBody style={{background: '#000', height: '90%'}}>
                <ul id='favoriteTeamInput' className={className} style={{listStyleType: 'none'}}>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>San Francisco 49ers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_SF' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Chicago Bears</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_CHI' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Cincinnati Bengals</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_CIN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Buffalo Bills</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_BUF' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Denver Broncos</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_DEN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Cleveland Browns</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_CLE' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Tampa Bay Buccaneers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_TB' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Arizona Cardinals</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_ARI' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Los Angeles Chargers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_LAC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Kansas City Chiefs</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_KC' style={{color: '#fff', right: 5}}/>
                  </li>
                  {/**/}
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Indianapolis Colts</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_IND' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Dallas Cowboys</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_DAL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Miami Dolphins</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_MIA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Philadelphia Eagles</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_PHI' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Atlanta Falcons</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_ATL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New York Giants</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_NYG' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Jacksonville Jaguars</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_JAC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New York Jets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_NYJ' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Detroit Lions</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_DET' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Green Bay Packers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_GB' style={{color: '#fff', right: 5}}/>
                  </li>
                  {/**/}
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Carolina Panthers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_CAR' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New England Patriots</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_NE' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Oakland Raiders</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_OAK' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Los Angeles Rams</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_LA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Baltimore Ravens</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_BAL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Washington Redskins</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_WAS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New Orleans Saints</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_NO' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Seattle Seahawks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_SEA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Pittsburgh Steelers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_PIT' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Houston Texans</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_HOU' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Tennessee Titans</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_TEN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Minnesota Vikings</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NFL_MIN' style={{color: '#fff', right: 5}}/>
                  </li>
                </ul>
              </ModalBody>
              <ModalFooter style={{background: '#000'}}>
                <Button color="secondary" onClick={toggle}>Done</Button>
              </ModalFooter>
            </Modal>
          </div>
      );
    case 'nba.refereechallenge.com':
      return (
          <div>
            <Button onClick={toggle} style={{width: '100%', background: '#1e1e1e'}}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
              <ModalHeader style={{background: '#000'}}>
                Select Favorite Teams
              </ModalHeader>
              <ModalBody style={{background: '#000', height: '90%'}}>
                <ul id='favoriteTeamInput' className={className} style={{listStyleType: 'none'}}>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Oklahoma City Thunder</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_OKC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Utah Jazz</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_UTA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Portland Trail Blazers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_POR' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Minnesota Timberwolves</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MIN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Boston Celtics</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_BOS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Houston Rockets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_HOU' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Chicago Bulls</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_CHI' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New Orleans Pelicans</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_NOP' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Dallas Mavericks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_DAL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Phoenix Suns</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_PHX' style={{color: '#fff', right: 5}}/>
                  </li>
                  {/**/}
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Toronto Raptors</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_TOR' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Orlando Magic</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_ORL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Sacramento Kings</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_SAC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Los Angeles Lakers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_LAL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Indiana Pacers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_IND' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Los Angeles Clippers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_LAC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Washington Wizards</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_WAS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Brooklyn Nets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_BKN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Denver Nuggets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_DEN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Philadelphia 76ers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_PHI' style={{color: '#fff', right: 5}}/>
                  </li>
                  {/**/}
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Milwaukee Bucks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MIL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Charlotte Hornets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_CHA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New York Knicks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_NYK' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Detroit Pistons</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_DET' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Cleveland Cavaliers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_CLE' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Miami Heat</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MIA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Atlanta Hawks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_ATL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Golden State Warriors</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_GSW' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>San Antonio Spurs</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_SAS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Memphis Grizzlies</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MEM' style={{color: '#fff', right: 5}}/>
                  </li>
                </ul>
              </ModalBody>
              <ModalFooter style={{background: '#000'}}>
                <Button color="secondary" onClick={toggle}>Done</Button>
              </ModalFooter>
            </Modal>
          </div>
      );
    default:
      return (
          <div>
            <Button onClick={toggle} style={{width: '100%', background: '#1e1e1e'}}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
              <ModalHeader style={{background: '#000'}}>
                Select Favorite Teams
              </ModalHeader>
              <ModalBody style={{background: '#000', height: '90%'}}>
                <ul id='favoriteTeamInput' className={className} style={{listStyleType: 'none'}}>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Oklahoma City Thunder</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_OKC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Utah Jazz</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_UTA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Portland Trail Blazers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_POR' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Minnesota Timberwolves</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MIN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Boston Celtics</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_BOS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Houston Rockets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_HOU' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Chicago Bulls</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_CHI' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New Orleans Pelicans</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_NOP' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Dallas Mavericks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_DAL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Phoenix Suns</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_PHX' style={{color: '#fff', right: 5}}/>
                  </li>
                  {/**/}
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Toronto Raptors</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_TOR' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Orlando Magic</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_ORL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Sacramento Kings</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_SAC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Los Angeles Lakers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_LAL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Indiana Pacers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_IND' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Los Angeles Clippers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_LAC' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Washington Wizards</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_WAS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Brooklyn Nets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_BKN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Denver Nuggets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_DEN' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Philadelphia 76ers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_PHI' style={{color: '#fff', right: 5}}/>
                  </li>
                  {/**/}
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Milwaukee Bucks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MIL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Charlotte Hornets</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_CHA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>New York Knicks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_NYK' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Detroit Pistons</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_DET' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Cleveland Cavaliers</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_CLE' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Miami Heat</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MIA' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Atlanta Hawks</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_ATL' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Golden State Warriors</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_GSW' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>San Antonio Spurs</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_SAS' style={{color: '#fff', right: 5}}/>
                  </li>
                  <li>
                    <Label style={{color: '#fff', padding: '5 0', marginLeft: 2}}>Memphis Grizzlies</Label>
                    <Input onChange={ (e) => {
                      handleTeamChangeLI();
                    }} className='favoriteTeamInput' type='checkbox' value='NBA_MEM' style={{color: '#fff', right: 5}}/>
                  </li>
                </ul>
              </ModalBody>
              <ModalFooter style={{background: '#000'}}>
                <Button color="secondary" onClick={toggle}>Done</Button>
              </ModalFooter>
            </Modal>
          </div>
      );
  }

};

class EmailForm extends Component {

  trophyImage = './assets/images/trophy.png';
  favoriteTeamBallImage = './assets/images/ball.png';

  constructor(props) {
    super(props);
    this.state = {
      favoriteTeams: [],
      email: '',
      validate: {
        emailState: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
      if (emailRex.test(e.target.value) && e.target.value !== '') {
        validate.emailState = 'has-success'
      } else {
        validate.emailState = 'has-danger'
      }
      this.setState({ validate })
    }

  submitEmail = () => {
    const emailApiURL = 'https://'+window.location.host+'/email.php';
    const selected = document.querySelectorAll('#favoriteTeams option:checked');
    const favoriteTeams = Array.from(selected).map(el => el.value);

    let params = {
      sessionId: this.props.sessionId,
      league: window.location.hostname.split('.')[0],
      email: this.state.email,
      collectedAt: this.props.collectedAt,
      favoriteTeams: favoriteTeams
    };

    fetch(emailApiURL, {
    	method: 'POST',
    	headers: {
    		'Accept': 'application/json',
    		'Content-Type': 'application/json',
    	},
    	body: JSON.stringify(params),
    })
    .then((response) => response.json())
    .then((voteResponse) => {
    	console.log('done');
    })
    .catch((error) => {
    	console.error('error');
    });
  };

  handleChange = async (event) => {
    await this.setState({
      email: event.target.value
    });
    this.setParams();
  };

  handleTeamChange = async (event) => {
    const selected = document.querySelectorAll('#favoriteTeams option:checked');
    const favoriteTeams = Array.from(selected).map(el => el.value);

    await this.setState({
      favoriteTeams: favoriteTeams
    });

    this.setParams();
  };

  handleTeamChangeLI = async () => {
    const selected = document.querySelectorAll('#favoriteTeamInput input:checked');
    const favoriteTeams = Array.from(selected).map(el => el.value);
    await this.setState({
      favoriteTeams: favoriteTeams
    });

    this.setParams();
  };

  setParams = () => {
    let params = {
      sessionId: this.props.sessionId,
      league: window.location.hostname.split('.')[0],
      email: this.state.email,
      collectedAt: this.props.collectedAt,
      favoriteTeams: this.state.favoriteTeams
    };

    let handleUserInputs  =   this.props.handleUserInputs;
    handleUserInputs(params);
  };

  favoriteTeams = (textStyle) => {
    // console.log("window.location.hostname", window.location.hostname);
    switch (window.location.hostname) {
      case 'nfl.refereechallenge.com':
        return (
            <FormGroup>
              {/*<Label style={textStyle}>Choose Your Favorite Team(s)</Label>*/}
              <Input
                  type="select"
                  name="favoriteTeams"
                  id="favoriteTeams"
                  multiple
                  onChange={ (e) => {
                    this.handleTeamChange(e);
                  }}
              >
                <option value='NBA_OKC'>Oklahoma City Thunder</option>
                <option value='NBA_UTA'>Utah Jazz</option>
                <option value='NBA_POR'>Portland Trail Blazers</option>
                <option value='NBA_MIN'>Minnesota Timberwolves</option>
                <option value='NBA_BOS'>Boston Celtics</option>
                <option value='NBA_HOU'>Houston Rockets</option>
                <option value='NBA_CHI'>Chicago Bulls</option>
                <option value='NBA_NOP'>New Orleans Pelicans</option>
                <option value='NBA_DAL'>Dallas Mavericks</option>
                <option value='NBA_PHX'>Phoenix Suns</option>
                <option value='NBA_TOR'>Toronto Raptors</option>
                <option value='NBA_ORL'>Orlando Magic</option>
                <option value='NBA_SAC'>Sacramento Kings</option>
                <option value='NBA_LAL'>Los Angeles Lakers</option>
                <option value='NBA_IND'>Indiana Pacers</option>
                <option value='NBA_LAC'>Los Angeles Clippers</option>
                <option value='NBA_WAS'>Washington Wizards</option>
                <option value='NBA_BKN'>Brooklyn Nets</option>
                <option value='NBA_DEN'>Denver Nuggets</option>
                <option value='NBA_PHI'>Philadelphia 76ers</option>
                <option value='NBA_MIL'>Milwaukee Bucks</option>
                <option value='NBA_CHA'>Charlotte Hornets</option>
                <option value='NBA_NYK'>New York Knicks</option>
                <option value='NBA_DET'>Detroit Pistons</option>
                <option value='NBA_CLE'>Cleveland Cavaliers</option>
                <option value='NBA_MIA'>Miami Heat</option>
                <option value='NBA_ATL'>Atlanta Hawks</option>
                <option value='NBA_GSW'>Golden State Warriors</option>
                <option value='NBA_SAS'>San Antonio Spurs</option>
                <option value='NBA_MEM'>Memphis Grizzlies</option>
              </Input>
            </FormGroup>
        );
      default:
        return (
            <FormGroup>
              {/*<Label style={textStyle}>Choose Your Favorite Team(s)</Label>*/}
              <Input
                  type="select"
                  name="favoriteTeams"
                  id="favoriteTeams"
                  multiple
                  onChange={ (e) => {
                    this.handleTeamChange(e);
                  }}
              >
                <option value='NFL_NE'>New England Patriots</option>
                <option value='NFL_BUF'>Buffalo Bills</option>
                <option value='NFL_MIA'>Miami Dolphins</option>
                <option value='NFL_NYJ'>New York Jets</option>
                <option value='NFL_PIT'>Pittsburgh Steelers</option>
                <option value='NFL_BAL'>Baltimore Ravens</option>
                <option value='NFL_CIN'>Cincinnati Bengals</option>
                <option value='NFL_CLE'>Cleveland Browns</option>
                <option value='NFL_JAC'>Jacksonville Jaguars</option>
                <option value='NFL_TEN'>Tennessee Titans</option>
                <option value='NFL_IND'>Indianapolis Colts</option>
                <option value='NFL_HOU'>Houston Texans</option>
                <option value='NFL_KC'>Kansas City Chiefs</option>
                <option value='NFL_LAC'>Los Angeles Chargers</option>
                <option value='NFL_OAK'>Oakland Raiders</option>
                <option value='NFL_DEN'>Denver Broncos</option>
                <option value='NFL_PHI'>Philadelphia Eagles</option>
                <option value='NFL_DAL'>Dallas Cowboys</option>
                <option value='NFL_WAS'>Washington Redskins</option>
                <option value='NFL_NYG'>New York Giants</option>
                <option value='NFL_MIN'>Minnesota Vikings</option>
                <option value='NFL_DET'>Detroit Lions</option>
                <option value='NFL_GB'>Green Bay Packers</option>
                <option value='NFL_CHI'>Chicago Bears</option>
                <option value='NFL_NO'>New Orleans Saints</option>
                <option value='NFL_CAR'>Carolina Panthers</option>
                <option value='NFL_ATL'>Atlanta Falcons</option>
                <option value='NFL_TB'>Tampa Bay Buccaneers</option>
                <option value='NFL_LA'>Los Angeles Rams</option>
                <option value='NFL_SEA'>Seattle Seahawks</option>
                <option value='NFL_ARI'>Arizona Cardinals</option>
                <option value='NFL_SF'>San Francisco 49ers</option>
              </Input>
            </FormGroup>
        );
    }
  };

  submitForm(e) {
    let handleToUpdate  =   this.props.handleToUpdate;
    e.preventDefault();
    if (this.state.email === '') {
      alert("Please enter a valid email");
    }
    else {
      this.setState({
        hasSubmitted: true
      });
      handleToUpdate(true);
      this.submitEmail();
    }
  }

  skipSubmit = () => {
    let skipSubmit  =   this.props.skipSubmit;
    if (skipSubmit !== null && skipSubmit !== undefined) {
      skipSubmit();
    }
  };

  showSkip = (show=true, continueSwiping) => {
    if (show) {
      return (
          <React.Fragment>
            <Label style={{marginTop: '0', fontSize: 12, color: '#fff'}}>{continueSwiping}</Label>
            <Col>
              <Button className='btn' onClick={this.skipSubmit} style={{zIndex:100000, background: '#2a2a2a', color: '#fff'}}>Skip</Button>
            </Col>
          </React.Fragment>
      );
    }
  };

  showModal = () => {
    return <TeamModal buttonLabel='Select Teams' className='favoriteTeamsClass' handleTeamChangeLI = {this.handleTeamChangeLI.bind(this)}/>;
  };

  render() {
    const { email } = this.state;
    let showSkipButton = !(this.props.totalClips === this.props.collectedAt);
    if (this.state.hasSubmitted || this.props.didPassEmail) {
      textStyle = {
        color: '#ffffff',
        textAlign: 'center',
        fontFamily: 'impact',
        fontSize: 16,
        paddingTop: '4em'
      };
      let thankYouText = 'Thanks for Playing! Check your email to see your results!';
      if (this.props.continueVoting) {
        thankYouText = 'Thank You! Continue Swiping to rate more videos!';
      }

      return (
          <Container className="App" style={{
            alignItems: 'center',
          }}>
              {/*Bubble 1 */}
              <div
                  style={{
                    width: '100%',
                    height: '40%',
                    padding: '10px',
                    marginTop: '1em',
                    border: '2px solid #fff',
                    borderRadius: '15px',
                    backgroundColor: '#000',
                    zIndex: 100,
                    alignItems: 'center',
                  }}
              >
                {/* Trophy Image */}
                <div
                    style={{
                      width: '3em',
                      height: '3em',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '1em',
                      // border: '1px solid #fff',
                      borderRadius: '5px',
                      backgroundColor: '#1e1e1e'
                    }}
                >
                  <img style={{width: '100%', padding: 5}} src={'./assets/images/trophy.png'} alt='results'/>
                </div>
                <p style={{
                  color: '#ffffff',
                  textAlign: 'center',
                  // fontFamily: 'impact',
                  marginTop: 5,
                  fontSize: 20
                }}>{thankYouText}</p>
              </div>
          </Container>
      );
    }
    else {
      let continueSwiping = '';
      if (this.props.continueVoting) {
        continueSwiping = 'Or simply continue Swiping to rate more videos!';
      }
      return (
        <Container className="App" style={{
          opacity: .80,
          alignItems: 'center',
          background: '#1e1e1e'
        }}>
          <Form className="form" onSubmit={ (e) => this.submitForm(e) }>
            {/*Bubble 1 */}
          <div
            style={{
              width: '100%',
              height: '40%',
              padding: '10px',
              marginTop: '1em',
              border: '2px solid #fff',
              borderRadius: '15px',
              backgroundColor: '#000',
              zIndex: 100,
              alignItems: 'center',
            }}
          >
            {/* Trophy Image */}
            <div
                style={{
                  width: '3em',
                  height: '3em',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '1em',
                  // border: '1px solid #fff',
                  borderRadius: '5px',
                  backgroundColor: '#1e1e1e'
                }}
            >
              <img style={{width: '100%', padding: 5}} src={'./assets/images/trophy.png'} alt='results'/>
            </div>
            {/* Get your Results */}
            <p style={{
              color: '#ffffff',
              textAlign: 'center',
              // fontFamily: 'impact',
              fontSize: 10,
              marginBottom: 0
            }}>Get</p>

            <p style={{
              color: '#ffffff',
              textAlign: 'center',
              // fontFamily: 'impact',
              marginTop: 0,
              fontSize: 15
            }}>Your Results!</p>

            <Col>
              <FormGroup>
                {/* Form Input */}
                <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="myemail@email.com"
                    value={ email }
                    valid={ this.state.validate.emailState === 'has-success' }
                    invalid={ this.state.validate.emailState === 'has-danger' }
                    onChange={ (e) => {
                      this.validateEmail(e);
                      this.handleChange(e);
                    } }
                />
                {/* Text Info */}
                <FormFeedback valid>
                  Lookin' good!
                </FormFeedback>
                <FormFeedback>
                  Uh oh! Looks like there is an issue with your email. Please input a correct email.
                </FormFeedback>
                <FormText>Enter your email to see how your votes stack up against the rest.</FormText>
              </FormGroup>
            </Col>
          </div>
            {/*Bubble 2 */}
            <div
                style={{
                  width: '100%',
                  height: '40%',
                  padding: '10px',
                  marginTop: '1em',
                  border: '2px solid #fff',
                  borderRadius: '15px',
                  backgroundColor: '#000',
                  zIndex: 100,
                  alignItems: 'center',
                }}
            >
              {/* Basketball Image */}
              <div
                  style={{
                    width: '3em',
                    height: '3em',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '1em',
                    // border: '1px solid #fff',
                    borderRadius: '5px',
                    backgroundColor: '#1e1e1e'
                  }}
              >
                <img style={{width: '100%', padding: 5}} src={'./assets/images/team.png'} alt='favorites'/>
              </div>
              {/* Get your Results */}
              <p style={{
                color: '#ffffff',
                textAlign: 'center',
                // fontFamily: 'impact',
                fontSize: 10,
                marginBottom: 0
              }}>Choose</p>

              <p style={{
                color: '#ffffff',
                textAlign: 'center',
                // fontFamily: 'impact',
                marginTop: 0,
                fontSize: 15
              }}>Your Favorite Team(s)!</p>

              {this.showModal()}
            </div>
            <Col>
              <Button className='btn' style={{
                zIndex:100000,
                background: '#e8e8e8',
                color: '#000',
                marginTop: '1em'
              }}>Submit</Button>
            </Col>

            {this.showSkip(showSkipButton,continueSwiping)}
          </Form>
        </Container>
      );
    }
  }
}

export default EmailForm;
